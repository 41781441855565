import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@app/ui-kit/layouts/main/main-layout.component';
import { UserRole } from '@app/core/domain';
import { AppSection } from '@app/core/models/enums/app-section.enum';
import { authorizedGuard } from '@app/core/guards/authorized.guard';
import { hasRoleGuard } from '@app/core/guards/has-role.guard';
import { notAuthorizedGuard } from '@app/core/guards/not-authorized.guard';
import { defaultRedirectGuard } from '@app/core/guards/default-redirect.guard';
import { isMobileGuard } from '@app/core/guards/is-mobile.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
        canActivate: [notAuthorizedGuard],
      },
      {
        path: 'static',
        loadChildren: () => import('./static/static.module').then((m) => m.StaticModule),
      },
      {
        path: '',
        component: MainLayoutComponent,
        canActivate: [authorizedGuard],
        children: [
          {
            path: '',
            canActivate: [defaultRedirectGuard],
            loadChildren: () => import('./profile/routes'),
            pathMatch: 'full',
          },
          {
            path: AppSection.PROFILE,
            loadChildren: () => import('./profile/routes'),
          },
          {
            path: AppSection.INCOME,
            loadChildren: () => import('./user-income/routes'),
          },
          {
            path: AppSection.SALARIES,
            loadChildren: () => import('./salaries/routes'),
          },
          {
            path: AppSection.TIMESHEET,
            loadChildren: () => import('./timesheet/routes'),
          },
          {
            path: AppSection.TIME_TRACKING,
            loadChildren: () => import('./time-tracking/routes'),
            canActivate: [isMobileGuard],
          },
          {
            path: AppSection.REPORTS,
            loadChildren: () => import('@app/reports/routes'),
          },
          {
            path: AppSection.SETTINGS,
            loadChildren: () => import('./settings/routes'),
            canActivate: [hasRoleGuard],
            data: { roles: [UserRole.SUPER_ADMIN, UserRole.HR] },
          },
          {
            path: AppSection.TAXES,
            loadChildren: () => import('./taxes/routes'),
            canActivate: [hasRoleGuard],
            data: {
              roles: [UserRole.SUPER_ADMIN, UserRole.HR, UserRole.ROLE_ACCOUNTANT],
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
