export class Division {
  id: number;
  name: string;
  address: string;
  type: DivisionType;
}

export class SubDivision {
  id: number;
  name: string;
  type: SubDivisionType;
}

export enum DivisionType {
  SHOP = 'SHOP',
  ADMINISTRATION = 'ADMINISTRATION',
  MANUFACTURE = 'MANUFACTURE',
  MANUFACTURE_LAZY_KITCHEN = 'MANUFACTURE_LAZY_KITCHEN',
  OTHER = 'OTHER',
  SHOP_SMALL_SUSHI = 'SHOP_SMALL_SUSHI',
  DELIVERY_SERVICE = 'DELIVERY_SERVICE',
}

export enum SubDivisionType {
  ADMINISTRATION = 'ADMINISTRATION',
  DELIVERY_SERVICE = 'DELIVERY_SERVICE',
  SHOP = 'SHOP',
  SHOP_SMALL_SUSHI = 'SHOP_SMALL_SUSHI',
  COOKING_SUB_DIVISION = 'COOKING_SUB_DIVISION',
  SECURITY_SUB_DIVISION = 'SECURITY_SUB_DIVISION',
  COOLED_FISH_SUB_DIVISION = 'COOLED_FISH_SUB_DIVISION',
  GASTRONOMY_SUB_DIVISION = 'GASTRONOMY_SUB_DIVISION',
  SUSHI_SUB_DIVISION = 'SUSHI_SUB_DIVISION',
  LAZY_KITCHEN_SUB_DIVISION = 'LAZY_KITCHEN_SUB_DIVISION',
  MANUFACTURE_SUB_DIVISION = 'MANUFACTURE_SUB_DIVISION',
  MANUFACTURE_LAZY_KITCHEN_SUB_DIVISION = 'MANUFACTURE_LAZY_KITCHEN_SUB_DIVISION',
  AUTO_SUB_DIVISION = 'AUTO_SUB_DIVISION',
  WAREHOUSE_SUB_DIVISION = 'WAREHOUSE_SUB_DIVISION',
  OTHER = 'OTHER',
}
