import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Division, DivisionRepo, SubDivision } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class DivisionService {
  constructor(private divisionRepo: DivisionRepo) {}

  public getAll(): Observable<Division[]> {
    return this.divisionRepo.getAll();
  }

  public getSubDivisions(topDivId: number): Observable<SubDivision[]> {
    return this.divisionRepo.getSubDivisions(topDivId);
  }
}
