import { Division, DivisionType, UserAccessPermission, UserRole } from '@app/core/domain';

export class SystemUser {
  public readonly ROLES_WITH_ALL_VIEW_PERMISSIONS: UserRole[] = [UserRole.SUPER_ADMIN];
  userId: number;
  fullName: string;
  topDivision?: UserTopDivision;
  subDivision?: UserSubDivision;
  roles: UserRole[];
  access?: UserAccess[];

  constructor(
    userId?: number,
    fullName?: string,
    roles?: UserRole[],
    topDivision?: UserTopDivision,
    subDivision?: UserSubDivision,
    access?: UserAccess[]
  ) {
    this.userId = userId ?? 0;
    this.fullName = fullName ?? '';
    this.roles = roles ?? [];
    this.topDivision = topDivision
      ? new UserTopDivision(topDivision.id, topDivision.name, topDivision.address, topDivision.type)
      : undefined;
    this.subDivision = subDivision ? new UserSubDivision(subDivision.id, subDivision.name) : undefined;
    this.access = access?.map((accessItem) => {
      return new UserAccess(accessItem.topDivision, accessItem.permissions);
    });
  }

  public getAllAvailableDivisions(): Division[] | null {
    const availableTopDivisions = this.access?.map((access) => access.topDivision);
    return availableTopDivisions?.length ? availableTopDivisions : this.topDivision ? [this.topDivision] : null;
  }

  public isHR(): boolean {
    return this.haveRoles(UserRole.HR);
  }

  public haveRoles(...roles: UserRole[]): boolean {
    for (const role of roles) {
      if (this.roles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  public canViewAll(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS);
  }

  public canViewFullSalarySection(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.HR);
  }

  public canViewSushiSalarySection(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.ROLE_SENIOR_SUSHI_MANAGER);
  }

  public canViewTimesheetSection(): boolean {
    return this.haveRoles(
      ...this.ROLES_WITH_ALL_VIEW_PERMISSIONS,
      UserRole.HR,
      UserRole.SECURITY_INSPECTOR,
      UserRole.ROLE_TOP_DIVISION_MANAGER,
      UserRole.ROLE_SUB_DIVISION_MANAGER,
      UserRole.ROLE_SENIOR_SUSHI_MANAGER
    );
  }

  public canViewReports(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.HR);
  }

  public canViewSushiStaffSalaryReport(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.ROLE_SUPER_SUSHI_MASTER);
  }

  public canViewSettings(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.HR);
  }

  public canViewTaxesSection(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.HR, UserRole.ROLE_ACCOUNTANT);
  }

  public canEditInTaxesSection(): boolean {
    return this.haveRoles(...this.ROLES_WITH_ALL_VIEW_PERMISSIONS, UserRole.ROLE_ACCOUNTANT);
  }

  public canEditAdministrationNorm(): boolean {
    return this.haveRoles(UserRole.HR);
  }

  public canEditFactComment(): boolean {
    return this.haveRoles(
      UserRole.HR,
      UserRole.ROLE_TOP_DIVISION_MANAGER,
      UserRole.ROLE_SUB_DIVISION_MANAGER,
      UserRole.ROLE_SENIOR_SUSHI_MANAGER
    );
  }

  public canFilterTimesheetByDivisions(): boolean {
    const availableDivisions = this.getAllAvailableDivisions();
    return this.isHR() || (!!availableDivisions && availableDivisions.length > 1);
  }

  public canFilterSushiByDivisions(): boolean {
    const availableDivisions = this.getAllAvailableDivisions();
    return this.isHR() || (!!availableDivisions && availableDivisions.length > 1);
  }

  public canEditInExtendedPeriod(): boolean {
    return this.haveRoles(UserRole.HR);
  }

  public canEditPlan(): boolean {
    return this.haveRoles(
      UserRole.HR,
      UserRole.ROLE_TOP_DIVISION_MANAGER,
      UserRole.ROLE_SUB_DIVISION_MANAGER,
      UserRole.ROLE_SENIOR_SUSHI_MANAGER
    );
  }

  public canEditSushiWorkersBonuses(): boolean {
    return this.haveRoles(UserRole.HR, UserRole.ROLE_SENIOR_SUSHI_MANAGER);
  }

  public canEditSushiTurnoverBonus(): boolean {
    return this.haveRoles(UserRole.HR);
  }

  public canEditSushiWorkerPosition(): boolean {
    return this.haveRoles(UserRole.HR, UserRole.ROLE_SENIOR_SUSHI_MANAGER);
  }

  public canEditSushiWorkerIncome(): boolean {
    return this.haveRoles(UserRole.HR, UserRole.ROLE_SENIOR_SUSHI_MANAGER);
  }

  public canEditSushiWorkerSalary(): boolean {
    return this.haveRoles(UserRole.ROLE_SENIOR_SUSHI_MANAGER);
  }
}

export class UserTopDivision {
  id: number;
  name: string;
  address: string;
  type: DivisionType;

  constructor(id: number, name: string, address: string, type: DivisionType) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.type = type;
  }
}

export class UserSubDivision {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class UserAccess {
  topDivision: Division;
  permissions: UserAccessPermission[];

  constructor(topDivision: Division, permissions: UserAccessPermission[]) {
    this.topDivision = topDivision;
    this.permissions = permissions;
  }
}
