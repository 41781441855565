import { DestroyRef, inject, Injectable } from '@angular/core';
import { MobileOS } from '@app/ui-kit/shared/models/mobile-os';
import { AppSection } from '@app/core/models/enums/app-section.enum';
import { environment } from '@environment/environment';
import { IMenuItem } from '@app/core/models/interfaces/menu-item.interface';
import { AuthenticationService } from '@app/core/services';
import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';
import { DeviceService } from '@app/core/domain';
import { catchError, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SystemUser } from '@app/core/models/classes/system-user';
import { StatisticsSection } from '@app/core/models/enums/statistcs-section.enum';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private destroyRef = inject(DestroyRef);
  private get user(): SystemUser {
    return this.authService.getUser();
  }

  constructor(private authService: AuthenticationService, private deviceService: DeviceService) {}

  private Tools = (): IMenuItem[] | void => {
    const menuItems: IMenuItem[] = [];

    if (this.user.canViewTimesheetSection()) {
      menuItems.push({
        icon: 'tools',
        name: 'timesheet',
        url: `${AppSection.TIMESHEET}`,
      });
    }
    if (this.user.canViewFullSalarySection() || this.user.canViewSushiSalarySection()) {
      menuItems.push({
        icon: 'money-tracker',
        name: 'salaries',
        url: `${AppSection.SALARIES}`,
      });
    }
    if (this.user.canViewTaxesSection()) {
      menuItems.push({
        icon: 'tools',
        name: 'taxes',
        url: `${AppSection.TAXES}`,
      });
    }
    if (menuItems.length) {
      return menuItems;
    }
  };

  private User = (): IMenuItem[] => {
    return [
      {
        icon: 'wallet-alt-outline',
        name: 'salary',
        url: `/${AppSection.INCOME}`,
      },
      {
        icon: 'document',
        name: 'documentation',
        externalUrl: environment.documentationUrl,
        openExternalUrlInNewTab: true,
      },
      { icon: 'profile', name: 'profile', url: `/${AppSection.PROFILE}` },
    ];
  };

  private TimeTracking = (): IMenuItem[] => {
    return [
      {
        icon: 'clock',
        name: 'openTracking',
        externalUrlOnClick: (): void => {
          this.deviceService
            .getDeviceToken()
            .pipe(
              catchError(() => of(null)),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((deviceToken) => {
              window.location.href = `egersundapp://tracker/data/?token=${deviceToken}&name=${
                this.authService.getUser()?.fullName
              }`;
            });
        },
        showOnlyOn: [MobileOS.Android],
      },
      {
        icon: 'download',
        name: 'installApp',
        externalUrl: environment.androidAppUrl,
        showOnlyOn: [MobileOS.Android],
      },
      {
        icon: 'clock',
        name: 'openTracking',
        externalUrl: `todo`,
        showOnlyOn: [MobileOS.iOS],
      },
      {
        icon: 'download',
        name: 'installApp',
        url: 'todo',
        showOnlyOn: [MobileOS.iOS],
      },
    ];
  };

  private Statistics = (): IMenuItem[] | void => {
    if (!this.user.canViewReports() && !this.user.canViewSushiStaffSalaryReport()) return;
    const menuItems: IMenuItem[] = [];
    if (this.user.canViewReports()) {
      menuItems.push(
        {
          icon: 'exchange-horizontal',
          name: 'staffMovement',
          url: `${AppSection.REPORTS}/${StatisticsSection.STAFF_MOVEMENT}`,
        },
        {
          icon: 'chart-line',
          name: 'efficiency',
          url: `${AppSection.REPORTS}/${StatisticsSection.EFFICIENCY}`,
        },
        {
          icon: 'money-cash-search',
          name: 'salaryByPosition',
          url: `${AppSection.REPORTS}/${StatisticsSection.SALARY_BY_POSITION}`,
        },
        {
          icon: 'money-withdraw',
          name: 'salaryByDivision',
          url: `${AppSection.REPORTS}/${StatisticsSection.SALARY_BY_DIVISION}`,
        },
        {
          icon: 'exchange-horizontal',
          name: 'vacationSickLeavesFull',
          url: `${AppSection.REPORTS}/${StatisticsSection.PAID_TIME_OFF}`,
        }
      );
    }
    if (this.user.canViewSushiStaffSalaryReport()) {
      menuItems.push({
        icon: 'alarm-clock-check',
        name: 'staffSalary',
        url: `${AppSection.REPORTS}/${StatisticsSection.STAFF_SALARY}`,
      });
    }
    return [
      {
        icon: 'clock',
        name: 'reports',
        menuItems,
      },
    ];
  };

  private Settings = (): IMenuItem[] | void => {
    if (!this.user.canViewSettings()) return;
    return [
      {
        icon: 'clock',
        name: 'settings',
        menuItems: [
          {
            icon: 'alarm-clock-check',
            name: 'tracking',
            url: `${AppSection.SETTINGS}`,
          },
        ],
      },
    ];
  };

  private builders = [this.Tools, this.User, this.TimeTracking, this.Statistics, this.Settings];

  public getMenu(): Array<IMenuItem[]> {
    const result: Array<IMenuItem[]> = [];
    this.builders.forEach((builder) => {
      const item = builder();

      if (item) {
        result.push(item);
      }
    });
    return result
      .map((menuItems) => {
        const mobileOs = DeviceUtils.getMobileOS();
        return menuItems.filter(
          (menuItem) => !menuItem.showOnlyOn || (mobileOs && menuItem.showOnlyOn.includes(mobileOs))
        );
      })
      .filter((section) => section.length);
  }
}
