import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { distinctUntilChanged, shareReplay, tap } from 'rxjs';
import { LayoutService } from '@app/core/services';
import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit {
  public isDesktop$ = this.layoutService.isDesktop$.pipe(distinctUntilChanged()).pipe(
    tap(() => {
      this.cdr.detectChanges();
    }),
    shareReplay()
  );
  public isMobile$ = this.layoutService.isMobile$.pipe();
  public menuCollapsed$ = this.layoutService.isMenuCollapsed$;
  public window = window;
  public showTimeTracker = DeviceUtils.isMobileDevice();

  constructor(private layoutService: LayoutService, private cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
